<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->

<template>
  <v-container>
    <!-- Hero Section -->
    <v-row class="hero-section">
      <v-col cols="12">
        <h1 class="headline mb-3">An AI-Enabled Alternative to PagerDuty + Cybersecurity</h1>
        <p class="subheading">With unstruct.ai, experience a new era of cybersecurity incident response and management...</p>
      </v-col>
    </v-row>

    <!-- Product Features -->
    <v-row class="my-5">
      <v-col cols="12" md="4" v-for="feature in features" :key="feature.title">
        <v-card outlined :class="isDarkTheme() ? 'feature-card-dark' : 'feature-card-light'">
          <v-card-title class="feature-card-title">{{ feature.title }}</v-card-title>
          <v-card-subtitle :class="textColor">{{ feature.description }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <!-- Pricing Plans -->
    <v-row>
      <v-col v-for="plan in plans" :key="plan.id" cols="12" md="4">
        <v-card raised class="pricing-card">
          <v-card-title class="headline">{{ plan.name }}</v-card-title>
          <v-card-text>
            <div>{{ plan.description }}</div>
            <div class="my-3 price">{{ plan.price }}</div>
            <div class="my-3"><strong>Features:</strong></div>
            <v-list>
              <v-list-item v-for="feature in getFeaturesforPlan(plan.id)" :key="feature.title" class="feature-item">
                <v-list-item-icon>
                  <v-icon color="success">mdi-rocket-launch</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ feature.title }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="selectPlan(plan)">Choose Plan</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" class="my-5">
      <v-col cols="12" class="text-center">
        <v-btn color="secondary" @click="goBack">Return to Previous Page</v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      plans: [
        { id: 'pro', name: 'Pro', price: 'unsales@unstruct.ai', description: 'Advanced features...' },
        { id: 'enterprise', name: 'Enterprise', price: 'unsales@unstruct.ai', description: 'Enterprise features...'},
        { id: 'enterprise+oncall', name: 'Enterprise + On Call', price: 'unsales@unstruct.ai', description: 'Enterprise features + On Call...'}
      ],
      features: [
        { title: 'Incident Copilot', description: 'Incident Response Orchestration w/20+ Integrations...' },
        { title: 'StoryCurve', description: 'Collaborative Cybersecurity DFIR, SOC & DFIQ and After-Activity Review Stories...' },
        { title: 'Uptime Umbrella', description: 'User-Centric Status Pages w/40+ Integrations and SLO Monitoring...' },
        { title: 'PeacePager', description: "Reliable On-call Scheduling & Notifications w/Slack Integration..."},
        { title: 'SignalSentry', description: 'High-Fidelity Signal Processing & Case Management...'},
        { title: 'TTPGuard', description: 'Monitoring & Observability of UnStructured Data and People Insights...'},
      ],
      extended_features: [
        { plan_id: 'pro', title: 'Plugin-Based Architecture with 20+ Integrations', description: 'Including Slack, PagerDuty, Jira, Google, Github, Zoom, Sentry, unstruct.ai Statuspage, and more.' },
        { plan_id: 'pro', title: 'Incident Custom Steps/Checklists', description: 'Streamline your process with SLA tracking, reminders, and easy configuration.' },
        { plan_id: 'pro', title: 'Custom Incident Fields', description: 'Tailor the platform to meet the unique attributes of your incidents.' },
        { plan_id: 'pro', title: 'Industry Leading OpsGPT Bot - Your Incident Copilot', description: 'Experience AI-assisted task assignment options, currently in Beta.' },
        { plan_id: 'pro', title: 'One-Click Task Creation/Update', description: 'Manage tasks with unparalleled simplicity and efficiency.' },
        { plan_id: 'pro', title: 'Automated Comms Reports', description: 'Receive executive and tactical reports generated by advanced AI, currently in Beta.' },
        { plan_id: 'pro', title: 'Automated After-Activity Review Checklists', description: 'Benefit from inclusive AI-generated insights and stories, currently in Beta.' },
        { plan_id: 'pro', title: 'Zoom Transcript Processing', description: 'Experience auto-summation and intelligent task suggestion functionalities.' },
        { plan_id: 'pro', title: 'Bi-Directional Sync w/Jira', description: 'Enjoy bi-directional sync with Jira.' },
        { plan_id: 'pro', title: 'Full G-Suite Integration', description: 'Lower the burden on your team w/automation.' },
        { plan_id: 'pro', title: 'Best Practices Enforcement', description: 'Built right into the platform; it’s training and education with the highest ROI.' },
        { plan_id: 'pro', title: 'TTP Monitoring', description: 'Rely on automated monitoring of Slack, Zoom, and document data for tactics, techniques, and procedures.' },
        { plan_id: 'pro', title: 'Dynamic Search and Engagement Filters', description: 'Enhance engagement with customizable filters for individuals, teams, services, runbooks, and more.' },
        { plan_id: 'pro', title: 'Dynamic Signal Processing', description: 'Navigate through the noise and manage pager storms effectively. Automatically transfer all the context as the issue escalates.' },
        { plan_id: 'pro', title: 'Probable Causes & Signal Aggregation', description: 'Seamlessly integrate with platforms like Sentry, Uptycs, Google, PagerDuty, and more. Currently in Beta.' },
        { plan_id: 'pro', title: 'Automated Annotations, Attribute Mining & Timelines', description: 'Foster comprehensive and collaborative incident management.' },
        { plan_id: 'pro', title: 'Modern, User-Centric Status Pages', description: 'Enjoy automated updates, user feedback, and incident mapping with specific pages.' },
        { plan_id: 'pro', title: 'Cron-style Scheduled Maintenace', description: 'Enjoy automated updates, user feedback, and incident mapping with specific pages.' },
        { plan_id: 'pro', title: 'SLO & Uptime Monitoring', description: 'Utilize multiple monitor types, API integration, and ready-to-use SLO monitoring.' },
        { plan_id: 'pro', title: 'Streamlined On-Call Handoff', description: 'Experience formal handoffs, reminders, and contextual summaries.' },
        { plan_id: 'pro', title: 'New Analytics Dashboards', description: 'Focus on people, feedback, and more.' },
        { plan_id: 'pro', title: 'Über Timeline', description: 'Access org-wide aggregation of key metrics and attributes, such as sentiment.' },
        { plan_id: 'pro', title: 'Tag-Based Auto Participant Pulling', description: 'Leverage tags and metadata for participant compilation, currently in Beta.' },
        { plan_id: 'pro', title: 'Best Practices & Queries Extraction', description: 'Extract valuable insights directly from metadata.' },
        { plan_id: 'pro', title: 'API Keys & OIDC OAuth, RBAC and Private Incidents', description: 'Integrate securely and easily.' },
        { plan_id: 'pro', title: 'AI-enabled Companion Assist', description: 'Get answers to up to 30 types of incident-related questions from our OpsGPT Bot, currently in Beta.' },
        { plan_id: 'pro', title: 'Cybersecurity Forensic Analysis', description: 'Employ automated analyzers, sigma rule creation, threat intelligence, and streamlined investigation with built-in search templates.' },
        { plan_id: 'pro', title: 'Automated Toil Tracking System', description: 'Systematically record, analyze, and manage work toil.' },
        { plan_id: 'pro', title: 'People and Feedback Dashboards', description: 'Never before seen insights.' },
        { plan_id: 'pro', title: 'Systematic On-call Handoffs', description: 'Because hand offs are messy.' },

        // Enterprise features
        { plan_id: 'enterprise', title: 'Everything in Pro plus the following:', description: '' },
        { plan_id: 'enterprise', title: 'StoryCurve: DFIR and SOC', description: 'Collaborative Cybersecurity DFIR & SOC...' },
        { plan_id: 'enterprise', title: 'Unlimited StatusPages w/ Unlimited Uptime Monitoring', description: 'User-Centric Status Pages & More...' },
        { plan_id: 'enterprise', title: 'SignalSentry', description: 'High-Fidelity Signals & Case Management...' },
        { plan_id: 'enterprise', title: 'TTPMon', description: 'Monitoring and Observability of UnStructured Data...' },
        { plan_id: 'enterprise', title: '99.9% uptime SLA', description: 'High reliability'},
        { plan_id: 'enterprise', title: '4-hour Response SLA', description: 'Quick turnaorund time' },
        { plan_id: 'enterprise', title: 'Personalized On-boarding', description: 'There when you need us' },
        { plan_id: 'enterprise', title: 'Personalized SRE Training and Modernization', description: 'There when you need us' },

        // Enterprise+On call features
        { plan_id: 'enterprise+oncall', title: 'Everything in Enterprise plus the following:', description: ''},
        { plan_id: 'enterprise+oncall', title: 'On-call Slack Contact Methods', description: 'On-call incident resolution.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Mobile Escalations/Ack', description: 'On-call incident automation.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Calendar Subscriptions', description: 'On-call training and best practices.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Hand-Offs', description: 'On-call security and compliance.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Alert Metrics', description: 'On-call reliability and scalability.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Noise Labeling', description: 'On-call incident management.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Scheduling', description: 'Simple, reliable on-call pages and scheduling.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Notifications', description: 'Reliable, real-time notifications.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Escalations', description: 'Automated on-call escalations.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Reporting', description: 'On-call reporting and analytics.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Integrations', description: 'On-call integrations with your favorite tools.' },
        { plan_id: 'enterprise+oncall', title: 'On-call API Access', description: 'On-call API for custom integrations.' },
        { plan_id: 'enterprise+oncall', title: 'On-call Rotations', description: '24x7x365 support.' },
        { plan_id: 'enterprise+oncall', title: 'OIDC login', description: 'On-call incident response.' },
      ],
    };
  },
  methods: {
    isDarkTheme() {
      return localStorage.getItem("dark_theme") === 'true';
    },
    textColor() {
      return this.isDarkTheme() ? 'white--text' : 'black--text';
    },
    getFeaturesforPlan(planId) {
      return this.extended_features.filter(feature => feature.plan_id === planId);
    },
    goBack() {
      this.$router.go(-1);
    },
    selectPlan(plan) {
      if (plan.id === 'enterprise' || plan.id === 'enterprise+oncall' || plan.id === 'pro') {
        this.sendEmail(plan.price, plan.name);
      } else {
        this.redirectToStripeCheckout(plan);
      }
    },
    sendEmail(emailAddress, planName) {
      const subject = `Interested in unstruct.ai ${planName} Plan`;
      const body = `Hi unstruct.ai Team,\n\n I am interested in purchasing the ${planName} plan for my organization. Please contact me to discuss further.\n\n Thanks,\n`;
      window.location.href = `mailto: ${emailAddress}?subject=${subject}&body=${body}`;
    },
    redirectToStripeCheckout(plan) {
      this.$store.dispatch('checkout/checkout', plan);
    },
  },
};
</script>


<style scoped>
.feature-card {
  background: linear-gradient(to right, #ffffff, #f8f8f8);

}
.feature-card-light {
  background: linear-gradient(to right, #ffffff, #f8f8f8);
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  border-radius: 8px;
  transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;
  overflow: hidden;

}

.feature-card-dark {
  background: linear-gradient(to right, #333, #444);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  border-radius: 8px;
  transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;
  overflow: hidden;
}

.feature-card-light:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
}

.feature-card-dark:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
}

.feature-card-title {
  font-size: 1.2rem;
  color: #4b5bd2;
  font-weight: bold;
  margin-bottom: 10px;
}

.feature-card-text {
  color: #333;
}
.hero-section {
  background: linear-gradient(45deg, #6DD5FA, #FF758C);
  color: white;
  padding: 30px;
}


.pricing-card {
  background: linear-gradient(to top right, #4b5bd2, #FFC107);
  color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,.1);
  border-radius: 8px;
  transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
}

.price {
  font-size: 1.6rem;
  color: #FFFFFF;
  font-weight: bold;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.7);
  display: inline-block;
  border-radius: 5px;
  margin: 10px 0;
}

.headline, .subheading {
  font-weight: bold;
}


.primary {
  color: #4b5bd2;
}

.secondary {
  color: #FFC107;
}

.feature-item {
  display: flex;
  align-items: center;
}
</style>
